import '@/src/styles/main.css'

import galite from 'ga-lite'
import picoapp from 'picoapp'
import rola from 'rola'
import paralless from 'paralless'
import lazy from '@/src/lib/images.js'
import lazyVideos from '@/src/lib/videos.js'
import tighpo from 'tighpo'

const parallax = paralless()

window.feature = {
  touch: !!(("ontouchstart" in window) || window.navigator && window.navigator.msPointerEnabled && window.MSGesture || window.DocumentTouch && document instanceof DocumentTouch)
}

const images = lazy()
const videos = lazyVideos()
const animations = rola()
const app = picoapp({
  cursor: require('@/src/lib/components/cursor.js').default
  // time: require('@/src/lib/time.js').default
})

/**
 * init
 */
images()
videos()
animations()
parallax()
app.mount()

galite('create', 'UA-96992953-1', 'auto')
galite('send', 'pageview')

tighpo('spaghetti', function () {
  var style = document.createElement('style')
  document.body.appendChild(style)
  style.sheet.insertRule('html, body { cursor: url(https://spaghet.now.sh), auto !important; }')
})
