import vsbl from 'vsbl'

export default function lazim (attr = 'data-video-src') {
  return function bind () {
    if (window.feature.touch) return

    const nodes = document.querySelectorAll('[' + attr + ']')

    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i]
      const vid = node.getElementsByTagName('video')[0]
      const src = node.getAttribute(attr)

      vid.oncanplaythrough = () => {
        node.classList.add('is-loaded')
      }

      node.removeAttribute(attr)

      vsbl(node)(() => {
        vid.src = src
      }).update()
    }
  }
}
