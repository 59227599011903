import { component } from 'picoapp'
import tickl from 'tickl'

export default component(({ node }) => {
  const cursor = node.querySelector('[data-cursor]')

  tickl(node, [
    [cursor, cursor.getAttribute('data-cursor')]
  ])
})
